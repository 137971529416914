<template>
  <div id="myfooter">
    <el-container>
      <el-footer>
        <a href="https://www.revolvermaps.com/livestats/53rm05ryxm5/" class="left"><img
            src="//rf.revolvermaps.com/h/m/a/0/ff0000/128/0/53rm05ryxm5.png" width="180" height="90" alt="Map"
            style="border:0;"></a>
        <!-- <a href="http://s11.flagcounter.com/more/LZa"><img 
            src="https://s11.flagcounter.com/count2/LZa/bg_FFFFFF/txt_000000/border_CCCCCC/columns_2/maxflags_10/viewers_0/labels_0/pageviews_0/flags_0/percent_0/" 
            alt="Flag Counter" 
            border="0"></a> -->
        <!-- <a href="https://clustrmaps.com/site/1c2fw"  title="Visit tracker"><img 
            src="//www.clustrmaps.com/map_v2.png?d=y4WTZRqWC-yoDl1HexRERmVSDXri9vCXCXgYSmWH4Eo&cl=ffffff" /></a> -->
            <!-- <script type="text/javascript" id="clustrmaps" src="//cdn.clustrmaps.com/map_v2.js?cl=ffffff&w=a&t=n&d=y4WTZRqWC-yoDl1HexRERmVSDXri9vCXCXgYSmWH4Eo&co=117799&cmo=ed7126&cmn=ff5353&ct=ff5353"></script> -->
            <a href='https://clustrmaps.com/site/1c2fw'  title='Visit tracker'><img src='//clustrmaps.com/map_v2.png?cl=0e5c08&w=a&t=n&d=y4WTZRqWC-yoDl1HexRERmVSDXri9vCXCXgYSmWH4Eo&co=b3bfd0&ct=ed7126'/></a>
        <!-- <div class="left">
          <canvas id="initializeMeLaterF" width="150" height="50" style="border:1px solid black;">This is a
            message that
            shows
            if the browser doesn't support HTML5 canvas, which all modern browsers should support now.</canvas>
        </div> -->
        <p class="center">PMhub</p>
        <div class="right">
          <img src="@/assets/img/home3.svg" width="90">
          <img src="@/assets/img/home1.svg" width="83">
        </div>

      </el-footer>
      <el-footer class="footer2">

        <a class="left" href="https://beian.miit.gov.cn" target="_blank"
          textvalue="湘ICP备2022005878号">湘ICP备2022005878号</a>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
  export default {
    name: 'MyfooterCompnent',
    data () {
      return {

      }
    },
    methods: {
      initCanvas () {
        var canvas = document.querySelector('#initializeMeLaterF')
        var context1 = canvas.getContext('2d')
        context1.fillStyle = "rgb(200,0,0)";
        context1.fillRect(10, 10, 55, 50);
        context1.fillStyle = "rgba(0, 0, 200, 0.5)";
        context1.fillRect(30, 30, 55, 50);
      }
    },
    mounted () {
      // this.initCanvas()
    }
  }
</script>

<style scoped lang="scss">
  .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    height: 120px;
    text-align: right;
    /* vertical-align: middle; */
    display: table
  }

  .el-footer.footer2 {
    height: 20px;
  }

  .center {
    display: table-cell;
    text-align: middle;
    vertical-align: middle;
  }

  .right {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
  }

  .left {
    display: table-cell;
    text-align: left;
    vertical-align: middle;
  }
</style>